import React from "react";
import { useTranslation } from "react-i18next";
import { ThemeProvider } from "emotion-theming";
import { Flex, Text, themes } from "@graphite/uneon";

import MenuSvg from "./images/menu.inline.svg";

const burgerSx = {
	display: ["block", "none"],
	position: "relative",
	cursor: "pointer",
	outline: "none",
	WebkitTapHighlightColor: "transparent",
	fill: "#ECF2F5",
};

const menuSx = {
	flexDirection: "column",
	borderRadius: "12px",
	backgroundColor: "bg.primary",
	cursor: "auto",
	transition: "opacity 0.25s ease-out",
	position: "absolute",
	top: "35px",
	right: "-20px",
	padding: "12px 24px",
	textAlign: "left",
};

const linkSx = {
	whiteSpace: "nowrap",
	lineHeight: "42px",
	outline: "none",
	textDecoration: "none",
	zIndex: 10,
};

type TProps = {
	readonly appUrl: string;
};

const Burger = ({ appUrl }: TProps) => {
	const { t } = useTranslation("translation", { useSuspense: false });
	const [isOpenBurger, switchBurger] = React.useState(false);

	const openBurgerHandler = React.useCallback(() => {
		switchBurger(!isOpenBurger);
	}, [isOpenBurger]);

	const menuStyle = React.useMemo(
		() => ({
			...menuSx,
			opacity: isOpenBurger ? 1 : 0,
		}),
		[isOpenBurger]
	);

	return (
		<Flex sx={burgerSx} onClick={openBurgerHandler}>
			<MenuSvg />
			<ThemeProvider theme={themes.light}>
				<Flex sx={menuStyle}>
					<Text
						as="a"
						href="/features"
						variant="bodymd"
						color="text.primaryalt"
						sx={linkSx}
					>
						{t("Features")}
					</Text>
					<Text
						as="a"
						href="//feedback.graphite.space"
						target="_blank"
						rel="noreferrer"
						variant="bodymd"
						color="text.primaryalt"
						sx={linkSx}
					>
						{t("Help")}
					</Text>
					<Text
						as="a"
						href={`${appUrl}login`}
						variant="bodymd"
						color="text.primaryalt"
						sx={linkSx}
					>
						{t("topbar.login")}
					</Text>
					<Text
						as="a"
						href={`${appUrl}signup`}
						variant="bodymd"
						color="text.primaryalt"
						sx={linkSx}
					>
						{t("topbar.signup")}
					</Text>
				</Flex>
			</ThemeProvider>
		</Flex>
	);
};

export default React.memo(Burger);
