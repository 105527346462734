import { themes } from '@graphite/uneon';

const breakpoints = ['700px', '1000px', '1400px'];

const gridcontainer = {
	display: 'grid',
	maxWidth: '1780px',
	width: '100%',
	gridTemplateColumns: [
		'repeat(6, [col-start] 1fr)',
		'repeat(12, [col-start] 1fr)',
		'repeat(12, [col-start] 1fr)',
		'repeat(12, [col-start] 1fr)',
	],
	gridGap: '0 30px',
	'> div': {
		gridColumn: [
			'col-start / span 6',
			'col-start / span 12',
			'col-start / span 12',
			'col-start / span 12',
		],
	},
	margin: '0 auto',
	paddingLeft: ['30px', '30px', '45px', '90px'],
	paddingRight: ['30px', '30px', '45px', '90px'],
};

export default {
	light: {
		...themes.light,
		breakpoints,
		variants: {
			...themes.light.variants,
			gridcontainer,
		},
	},
	dark: {
		...themes.dark,
		breakpoints,
		variants: {
			...themes.dark.variants,
			gridcontainer,
		},
	},
};
