import React from "react";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Flex, Button, Text } from "@graphite/uneon";
import { useStaticQuery, graphql } from "gatsby";

import rhombusImage from "../../images/rhombus.svg";

type UrlData = {
	site: {
		siteMetadata?: {
			appUrl: string;
		};
	};
};

const Wrapper = styled(Flex)`
	background: url(${rhombusImage}) center / contain no-repeat;
	position: relative;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 500px;
	text-align: center;
	overflow: hidden;

	&:before {
		content: "";
		position: absolute;
		background: #000;
		width: 374px;
		height: 374px;
		border-radius: 100%;
		box-shadow: 0px 0px 120px 100px #000;
		filter: blur(96px);
		z-index: 1;
		transform: translate3d(0, 0, 0);
	}

	@media (min-width: 700px) {
		&:before {
			background: #030304;
			filter: blur(108px);
			box-shadow: 0px 0px 120px 100px #030304;
			opacity: 0.9;
			width: 446px;
			height: 446px;
		}
	}
`;

const Try = () => {
	const { t } = useTranslation("translation", { useSuspense: false });

	const { site }: UrlData = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						appUrl
					}
				}
			}
		`
	);

	const appUrl = site.siteMetadata?.appUrl || "/";

	return (
		<Wrapper
			mb="160px"
			mt="90px"
			pl={["15px", "30px", "45px", "60px"]}
			pr={["15px", "30px", "45px", "60px"]}
		>
			<Text
				variant="hero3"
				color="text.primaryalt"
				pl={["15px", "30px", "45px", "60px"]}
				pr={["15px", "30px", "45px", "60px"]}
				mt={["20px", "70px", "0"]}
				sx={{ position: "relative", zIndex: 2 }}
			>
				{t("about.title.heading")}
			</Text>
			<Text
				variant="leadsm"
				color="text.primary"
				mt={["30px", "30px", "18px"]}
				pl={["15px", "30px", "45px", "60px"]}
				pr={["15px", "30px", "45px", "60px"]}
				sx={{ position: "relative", zIndex: 2 }}
			>
				{t("about.title.text")}
			</Text>
			<Button
				as="a"
				href={`${appUrl}signup`}
				variant="warning.rounded.lg"
				mt={["50px", "50px", "36px"]}
				sx={{ position: "relative", zIndex: 2 }}
				data-link="try"
			>
				{t("about.title.cta")}
			</Button>
		</Wrapper>
	);
};

export default React.memo(Try);
