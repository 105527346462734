import React from "react";
import styled from "@emotion/styled";
import { useTranslation } from "react-i18next";
import { Text, Flex, Box } from "@graphite/uneon";
import Obfuscate from "react-obfuscate";

import LogoSvg from "../../images/logo-small.inline.svg";

const linkHeaderSx = {
	margin: "0 18px 24px",
	textDecoration: "none",
};

const linkSx = {
	margin: ["0 10px 12px", "0 10px 24px"],
	textDecoration: "none",
};

const EmailLink = styled(Obfuscate)`
	margin: 0 10px 24px;
	text-decoration: none;
`;

const Footer = () => {
	const { t } = useTranslation("translation");

	return (
		<Box variant="gridcontainer" textAlign="center" pb="80px">
			<Flex
				sx={{
					flexDirection: ["column", "row"],
					justifyContent: "center",
				}}
				mt="18px"
			>
				<Text
					as="a"
					href="https://www.facebook.com/graphite.space/"
					variant="title5"
					color="text.primaryalt"
					sx={linkHeaderSx}
					target="_blank"
				>
					{t(`footer.socials.facebook`)}
				</Text>
				<Text
					as="a"
					href="https://twitter.com/graphite_space"
					variant="title5"
					color="text.primaryalt"
					sx={linkHeaderSx}
					target="_blank"
				>
					{t(`footer.socials.twitter`)}
				</Text>
				<Text
					as="a"
					href="https://www.youtube.com/channel/UCCzN6VL5FSrzF8NccIhBs1Q"
					variant="title5"
					color="text.primaryalt"
					sx={linkHeaderSx}
					target="_blank"
				>
					{t(`footer.socials.youtube`)}
				</Text>
			</Flex>
			<Flex
				sx={{
					justifyContent: "center",
					flexDirection: ["column", "row"],
					marginBottom: ["10px", 0],
				}}
				mt="12px"
			>
				<Text
					as="a"
					href="https://changelog.graphite.space/"
					variant="headlinesm"
					color="text.tertiary"
					sx={linkSx}
					target="_blank"
				>
					Changelog
				</Text>
				<Text
					as="a"
					href="/terms_of_use/"
					variant="headlinesm"
					color="text.tertiary"
					sx={linkSx}
					target="_blank"
				>
					{t(`footer.terms`)}
				</Text>
				<Text
					as="a"
					href="/privacy_policy/"
					variant="headlinesm"
					color="text.tertiary"
					sx={linkSx}
					target="_blank"
				>
					{t(`footer.policy`)}
				</Text>
				<EmailLink email="support@graphite.space" target="_blank">
					<Text variant="headlinesm" color="text.tertiary">
						Contact us
					</Text>
				</EmailLink>
			</Flex>
			<Box mt="10px">
				<LogoSvg />
			</Box>
			<Text
				variant="headlinesm"
				color="text.tertiary"
				mt={["10px", "20px"]}
			>
				© {new Date().getFullYear()}
			</Text>
		</Box>
	);
};

export default React.memo(Footer);
